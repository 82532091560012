@import './mixins';

// Colors
@import './colors';

.ql-editor{
  height: 200px;
  overflow: auto;
}



tr {
  height: 60px;
  border-bottom: 1px solid #E8E8E8;

  // &.drapStart{
  //   box-shadow: 1px 1px #000;
  // }

  &.subCategory {
    .user_detail {
      margin-left: 10px;

      &::before {
        content: "-";
        display: inline-block;
        margin-right: 5px;
      }
    }
  }

  &.subSubCategory {
    .user_detail {
      margin-left: 20px;

      &::before {
        content: "--";
        display: inline-block;
        margin-right: 5px;
      }
    }
  }
}


.cat_img {
  height: 40px;
  width: 50px;
  object-fit: contain;
}

.user_detail {
  display: flex;
  align-items: center;
  cursor: pointer;
}

img.user_imgs {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  object-fit: cover;
}
span.truncate-css {
  width: 245px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}
.user_name {
  margin: 0px 10px;
}

p.user_info {
  font-size: 12px;
  font-weight: 400;
  color: #84818A;
  margin-bottom: 0px;
}

.d-none{
  display: none !important;
}

input[autocomplete="off"] {
  -webkit-autocomplete: none;
  -moz-autocomplete: none;
  -ms-autocomplete: none;
  -o-autocomplete: none;
  autocomplete: none;
}

.parimarybtn {
  border-radius: 8px;
  background: #fff !important;
  color: #202020 !important;
  border: 1px solid #D0D5DD !important;
  padding: 8px 15px;
}

.parimarybtn:hover {
  background: #fff !important;
  color: #000 !important;
}

.parimarybtn:focus {
  box-shadow: none !important;
}

.d-relative {
  position: relative;
}

i.fa.fa-trash.text-danger.icon-css {
  position: absolute;
  right: -20px;
}

.paginationWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px !important;
  >span {
    font-size: 13px;
    color: #84818A;
  }
}

.chip {
  background: #7561a2;
}

.alignTopDate div.ant-picker-input {
  top: -12px;
}

button:focus:not(:focus-visible) {
  outline: 0;
  box-shadow: none !important;
}

.react-pagination-js-default {
  ul {
    display: flex;
    align-items: center;
    column-gap: 10px;

    >li {
      margin: 0 !important;
      font-size: 13px !important;
      padding: 7px 13px !important;

      &:first-child {
        padding: 0 !important;

        &:not(.disabled) {
          color: #1F7BF4 !important;
        }

        &::after {
          content: "< Previous";
        }

        a {
          display: none;
        }
      }

      &:last-child {
        padding: 0 !important;

        &:not(.disabled) {
          color: #1F7BF4 !important;
        }

        &::after {
          content: "Next >";
        }

        a {
          display: none;
        }
      }

      &.page {
        border-color: #0000 !important;
        background-color: transparent !important;
        box-shadow: none !important;
        font-weight: 400 !important;
      }

      &.is-active {
        font-weight: 500 !important;
        background-color: #fff !important;
        border-color: #1F7BF4 !important;
        box-shadow: none !important;
        text-shadow: none !important;
        color: #202020 !important;
      }
    }

  }

}

body {
  
  font-family: "DM Sans", sans-serif;
}

a {
  color: #1E5DBC;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font2;
}

label {
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
  color: #202020;
}

.form-row>.col,
.form-row>[class*=col-] {
  padding-right: 10px;
  padding-left: 5px;
  align-items: center;
}

.col_side div span {
  font-size: 16px;
  font-weight: 300;
  color: #202020;
}

:where(.css-dev-only-do-not-override-14wwjjs).ant-picker-focused {
  border-color: #e9e9e9 !important;
  // box-shadow: 0 0 0 2px rgb(255 255 255 / 10%) !important;
}

:where(.css-dev-only-do-not-override-14wwjjs).ant-picker:hover,
:where(.css-dev-only-do-not-override-14wwjjs).ant-picker-focused {
  border-color: #e4e4e4 !important;
}

:where(.css-dev-only-do-not-override-14wwjjs).ant-picker-range .ant-picker-active-bar {
  background: #765aa6 !important;
}

:where(.css-dev-only-do-not-override-14wwjjs).ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
:where(.css-dev-only-do-not-override-14wwjjs).ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
:where(.css-dev-only-do-not-override-14wwjjs).ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  color: #fff;
  background: #765aa6 !important;
}

:where(.css-dev-only-do-not-override-14wwjjs).ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-in-range::before {
  background: #7557a63b !important;
}

:where(.css-dev-only-do-not-override-14wwjjs).ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
:where(.css-dev-only-do-not-override-14wwjjs).ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
  background: #7557a63b !important;
}

:where(.css-dev-only-do-not-override-14wwjjs).ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border: 1px solid #765aa6 !important;
}

i.fa {
  cursor: pointer;
}

.dateRangePicker {
  width: 100% !important;
  border-radius: 8px;
  padding: 21px 10px;
  border: 1px solid #DCDCDC;
  height: calc(1.5em + 0.75rem + 2px);

}

.react-datepicker__close-icon::after {
  cursor: pointer;
  background-color: red;
}

.star {
  color: red;
}

.action_icons {
  display: flex;
}

a.edit_icon {
  border: 1px solid #DCDCDC;
  height: 30px;
  width: 30px;
  align-items: center;
  display: flex;
  justify-content: center;
  border-radius: 5px;
  margin-right: 6px;

  &:hover {
    text-decoration: none !important;
  }
}

.mainarea {
  background: #f8f9fa;
  padding: 27px;
  margin-top: 70px;
  min-height: calc(100vh - 72px) !important;
  max-height: 100%;
  padding-bottom: 100px;
}

span.edit_icon {
  border: 1px solid #DCDCDC;
  height: 30px;
  width: 30px;
  align-items: center;
  display: flex;
  justify-content: center;
  border-radius: 5px;
  margin-left: 3px;
}

i.material-icons.edit {
  font-size: 15px;
  font-weight: 600;
  color: #898989;

}

i.material-icons.delete {
  font-size: 15px;
  font-weight: 600;
  color: #898989;

}

i.fa.fa-arrow-left {
  color: #000;
}

// .table-striped tbody tr:nth-of-type(odd) {
//   background-color: #F9F9F9 !important;
// }

// .table th,
// .table td {
//   padding: 0.75rem;
//   vertical-align: top;
//   border-bottom: 1px solid #fff !important;
//   // text-transform: capitalize;
//   white-space: nowrap;
// }

body {
  font-family: 'Lato', sans-serif;


}

.inputWrapper {
  position: relative;

  .fa {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: #84818A;
  }
}

.modal-title,
.dropdown-item {
  text-transform: capitalize;
}

input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #84818A !important;
  opacity: 1 !important;
  /* Firefox */
}

input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #000 !important;
  opacity: 1 !important;
}

.loaderDiv {
  z-index: 99999;
  backdrop-filter: blur(2px);
  align-items: center;
  justify-content: center;
  display: flex;
  position: fixed;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
}

.pageLoader {
  max-width: 50px;
  margin: 0 auto;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #A0A4A8;
  opacity: 1;
  font-size: 14px;
  font-weight: 400;
  /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #A0A4A8;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #A0A4A8;
}

main.main {
  background-color: #F6F6F6 !important;
}

// Loader start
img.loaderlogo {
  max-width: 50px;
  // animation: rotation 3s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

// Loader end

.btn {
  text-transform: capitalize;
  font-weight: 500;
  padding: 10px 18px;
  font-size: 14px;
  border-radius: 10px;
}


.btn-outline-primary {
  color: #1E5DBC;
  border-color: #1E5DBC;
}

a {
  cursor: pointer;
}

.taableWrapper {
  max-height: calc(100vh - 250px);
}

button.btn.btn-primary.loginclass {
  background: #1E5DBC;
  border-radius: 5px;
  width: 100%;
  height: 49px;
  font-size: 14px;
  font-weight: 600;
  padding: 5px 15px;
}

@media(max-width:768px) {
  .taableWrapper {
    max-height: calc(100vh - 300px);
  }
}

.login-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;

  >.container {
    max-width: 500px;

    .shadow {
      border-top: 3px solid $primaryColor;
    }

    .login-logo {
      width: 100%;
      max-width: 150px;
    }
  }
}

.bginput {
  border: 1px solid #E8E8E8 !important;
  // background: #F6F6F6 !important;
  border-radius: 8px !important;
}

.empty-box {
  padding: 15px;
  text-align: center;

  .icon-box {
    padding: 15px 30px;
    font-size: 26px;
    margin-bottom: 10px;
  }
}

h3.text-left.lgtext {
  font-weight: 600;
  font-size: 24px;
}

.paraclss {
  font-weight: 400;
  font-size: 20px;
  line-height: 29px;
  color: #1E5DBC !important;
}


::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #F6F6F6;
  opacity: 1;
  /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #F6F6F6;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #F6F6F6;
}

.modal{
  background-color:#0000008a;
}

.modal {
  overflow-y: auto;
  background-color:#0000008a;
  .card-body,
  .modal-boldy {
    max-height: calc(100vh - 200px);
    overflow-y: auto;
  }
}

.upload-btn {
  position: relative;

  >input {
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }
}

.pagination {

  >li {
    padding: 2px;

    a {
      padding: 7px 10px;
      display: inline-block;
      border: 1px solid #E8E8E8;
      border-radius: 4px;
      font-size: 14px;
      min-width: 40px;
      text-align: center;
      color: #000;
      text-decoration: none;

    }

    &.active {
      a {
        background-color: $primaryColor;
        border-color: $primaryColor;
        color: #fff;
      }

    }

    &.disabled {
      a {
        border-color: #E8E8E8;
        color: #E8E8E8;
      }
    }
  }

}


.shine {
  background: #eee;
  background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
  background-size: 200% 100%;
  animation: 1.5s shine linear infinite;
  min-height: 20px;
  border-radius: 5px;
}


.shine.shineCard {
  height: 250px;
}

strong.shine {
  display: block;
  margin-bottom: 14px;
}


@keyframes shine {
  to {
    background-position-x: -200%;
  }
}



.table td,
.table th {
  padding: 0.75rem;
  vertical-align: middle;
}

// edit icon css
i.fa.fa-pencil-alt.edit {
  margin: 0px 15px;
  font-size: 18px;
  color: #7561a2;
  font-weight: 700;
}





// Responsive Design 
.buttons_Section {
  display: flex;
  align-items: center;
  margin-right: 12px;
}

.cardList {
  margin-top: 2rem;
}

a.btn.btn-primary.mr-2 {
  white-space: nowrap;
}

.form-control {
  padding: 21px 10px;
  border: 1px solid #DCDCDC;
  border-radius: 8px;
  width: 100%;
}

select.form-control.types {
  height: 43px;
  margin-right: 12px;
  width: 100%;
  max-width: 200px;
}

.new_cards {
  background: #f6f6f6;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  max-height: 300px;
  // margin-bottom: 4.2rem;
}

input.searchbar.mr-2 {
  padding: 8px;
  border-color: #cdd4da;
  border-radius: 5px;
  outline: none;
  border: 1px solid #cdd4da;
  height: 43px;
}

.d-flex.justify-content-between.align-items-center {
  flex-wrap: wrap;
}

@media only screen and (min-width:1200px) and (max-width:1400px) {
  .dropdown.addDropdown.chnageses {
    margin-top: 12px;
  }

  .dropdown.addDropdown.chnageset {
    margin-top: 12px;
  }

  .sales_section {
    padding: 20px;
  }

  .graph_section {
    padding: 20px;
  }

  .dropdown.addDropdown.chnagest {
    margin-left: 10px !important;
  }
}

@media only screen and (min-width:1001px) and (max-width:1199px) {
  .dropdown.addDropdown.chnagest {
    margin-left: 10px !important;
  }

  // .dropdown.addDropdown.chnages {
  //   margin-top: 10px;
  // }
  article.d-flex {
    margin-top: 1rem !important;
  }
}


@media only screen and (min-width:768px) and (max-width:1000px){
  .w-\[calc\(100\%-80px\)\] {
    width: 100% !important;
}

}



@media only screen and (min-width:991px) and (max-width:1000px) {
  .dropdown.addDropdown.chnagesg {
    margin-top: 0px;
  }

  .min-sidebar~div>.main-sidebar {
    display: none !important;

  }

  .main-navbar.min-sidebar {
    padding-left: 60px;
    display: flex;
  }

  // .main-sidebar {
  //   position: absolute;
  //   top: 143px;
  //   z-index: 998;
  // }

  .main-navbar {
    padding-left: 25px;
  }

  .sidebar-brand.p-3.pt-4.text-left.pl-5 {
    display: none;
  }

  .min-sidebar~div>main.main {
    width: 100% !important;
  }

  .d-flex.justify-content-between.align-items-end.mb-0 {
    display: block !important;
  }

  article.d-flex {
    margin-top: 1rem;
  }

  // .main-wrapper main.main {
  //   width: 100% !important;
  // }

  .d-flex.justify-content-between.align-items-end.mb-3 {
    display: block !important;
  }

  .selectDropdown::placeholder {
    width: 100px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  span {
    font-size: 14px;
  }

  span.side_head {
    font-size: 14px;
  }

  .dropdown.addDropdown.chnagest {
    margin-left: 10px !important;
  }

}

@media screen and (max-width:630px) {

  .dropdown.addDropdown.features.mr-2 {
    margin-top: 14px;
  }
}

@media only screen and (min-width:601px) and (max-width:621px) {
  .dropdown.addDropdown.chnages {
    margin-top: 15px !important;
  }

  article.d-flex {
    flex-wrap: wrap !important;
    display: flex;
  }

  .dropdown.addDropdown.chnagest_name {
    margin-top: 13px;
  }

}

@media only screen and (min-width:601px) and (max-width:615px) {

  //   .dropdown.addDropdown.chnagesname {
  //     margin-top: 15px !important;
  // }
  article.d-flex {
    flex-wrap: wrap !important;
    display: flex;
  }
}

@media screen and (min-width:601px)and (max-width:609px) {
  .dropdown.addDropdown.region_currency {
    margin-top: 15px;
  }
}

@media only screen and (min-width:601px) and (max-width:990px) {

  // .min-sidebar~div>.main-sidebar {
  //   display: none !important;

  // }

  .min-sidebar~div>.main-sidebar {
    margin-top: 0px !important;

  }



  .main-navbar.min-sidebar {
    padding-left: 60px;
    display: flex;
  }


  .main-navbar {
    padding-left: 60px;
  }

  .sidebar-brand.p-3.pt-4.text-left.pl-5 {
    display: none;
  }

  // .min-sidebar~div>main.main {
  //   width: 100% !important;
  // }




  .selectDropdown::placeholder {
    width: 100px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .selectDD {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }


  span.side_head {
    font-size: 14px;
  }

  .dropdown.addDropdown.chnagest {
    margin-left: 15px;
  }
}

@media screen and (max-width:908px) {
  .dropdown.addDropdown.city_status {
    margin-top: 14px;
  }
}

@media screen and (max-width:900px) {
  .dropdown.addDropdown.chnageses {
    margin-top: 15px;
  }

  .dropdown.addDropdown.city_status {
    margin-top: 14px;
  }
}

@media only screen and (max-width:767px) {
  .text-right {
    margin-bottom: 15px !important;
  }

  .roleTable tr td {
    width: 150px !important;
    max-width: 150px !important;
    min-width: 150px !important;
  }

  .col-md-9.head_cls {
    margin-bottom: 20px;
  }

  article.d-flex {
    display: flex !important;
    margin-top: 1rem !important;
    flex-wrap: wrap;
  }

  .d-flex.justify-content-between.align-items-center {
    /* flex-wrap: wrap; */
    display: block !important;
  }
}

@media screen and (max-width:766px) {
  .dropdown.addDropdown.chnages_date {
    margin-top: 15px;
  }
}

@media screen and (max-width:759px) {
  .dropdown.addDropdown.region_status {
    margin-top: 15px;
  }
}

@media screen and (max-width:751px) {
  .dropdown.addDropdown.chnagess {
    margin-top: 15px;
  }

  .dropdown.addDropdown.chnageses {
    margin-top: 15px;
  }
}

@media screen and (max-width:748px) {
  .dropdown.addDropdown.city_region {
    margin-top: 14px;
  }

  .dropdown.addDropdown.city_status {
    margin-top: 14px;
  }
}

@media screen and (max-width:738px) {
  .dropdown.addDropdown.chnageset {
    margin-top: 15px;
  }
}

@media screen and (min-width:601px) and (max-width:695px) {
  .icons_tab {
    margin-top: 0px;
  }
}

@media only screen and (min-width:528px) and (max-width:600px) {
  .dropdown.addDropdown.region_status {
    margin-top: 0px;
  }

  .dropdown.addDropdown.chnagesg {
    margin-top: 15px;
  }

  .dropdown.addDropdown.chnages_status {
    margin-top: 15px;
  }

  .dropdown.addDropdown {
    margin-top: 15px;
  }

  button.btn.btn-primary.mr-2.export {
    margin-left: 15px;
  }

  .dropdown.addDropdown.chnagesg {
    margin-top: 15px;
  }

  .icons_tab {
    margin-top: 15px;
  }

  .d-flex.justify-content-between.align-items-center {
    /* flex-wrap: wrap; */
    display: block !important;
  }

  article.d-flex {
    display: block !important;
    margin-top: 1rem !important;
  }

  .min-sidebar~div>.main-sidebar {
    display: none !important;

  }

  .main-navbar.min-sidebar {
    padding-left: 60px;
    display: flex;
  }

  .main-sidebar {
    position: absolute;
    top: 143px;
    z-index: 998;
  }

  .main-navbar {
    padding-left: 60px;
  }

  .sidebar-brand.p-3.pt-4.text-left.pl-5 {
    display: none;
  }

  .min-sidebar~div>main.main {
    width: 100% !important;
  }


  img.listIc {
    height: 40px;
    width: 40px;
  }

  form.headerSearch.ml-3 {
    width: auto;
    max-width: 130px;
  }


  span.side_head {
    font-size: 14px;
  }



  button#dropdownMenuButtonstatusDropdown\ reset {
    margin-top: 15px;
  }

  .dropdown.addDropdown.chnages {
    margin-top: 15px;
  }

  .dropdown.addDropdown.chnagest {
    margin-top: 15px;
  }

}

@media screen and (max-width:578px) {
  .dropdown.addDropdown.chnagesapp {
    margin-top: 15px;
  }
}

@media screen and (max-width:590px) {
  button.btn.btn-primary.btnreset {
    margin-top: 15px !important;
  }

  a.btn.btn-primary.btnreset {
    margin-top: 15px !important;
  }

}


@media only screen and (min-width:320px) and (max-width:527px) {
  .dropdown.addDropdown.region_status {
    margin-top: 0px;
  }

  .icons_tab {
    margin-top: 15px;
  }

  .dropdown.addDropdown.chnagesg {
    margin-top: 15px;
  }

  .min-sidebar~div>.main-sidebar {
    display: none !important;

  }

  .main-navbar.min-sidebar {
    padding-left: 40px;
    display: flex;
  }

  .main-sidebar {
    position: absolute;
    top: 143px;
    z-index: 998;
  }

  .main-navbar {
    padding-left: 40px;
  }

  .sidebar-brand.p-3.pt-4.text-left.pl-5 {
    display: none;
  }


  // proffile dropdown
  .dropdown.ml-auto {
    top: -30px;
    right: -16px;
  }

  .headerSearch {
    top: 20px !important;
  }



  // main p-age


  .d-flex.justify-content-between.align-items-end.mb-3 {
    display: block !important;
  }

  .nav-tabs {
    border-radius: 3px !important;
    height: 40px !important;
    background: none !important;
    border: none !important;
    margin-top: 1rem;
  }

  .icons_tab {
    margin: 0px !important;
  }

  .nav-tabs {
    border-bottom: 1px solid #ffffff;
  }

  article.d-flex {
    display: block !important;
    margin-top: 1rem !important;
  }

  .new_cards {
    height: 100% !important;
    margin-bottom: 0rem !important;
    max-height: 500px !important;
    //  height: 100%;
    //   margin-bottom: 0rem !important;
  }

  li.list_names {
    text-align: left !important;
  }

  .user_proff {
    display: block !important;
  }

  // category css
  .d-flex.justify-content-between.align-items-end.mb-0 {
    display: block !important;
  }

  select.form-control.types {
    margin-top: 13px;
    width: 100%;
  }

  .d-flex.justify-content-between.mb-3 {
    display: block !important;
  }

  .d-flex.justify-content-between {
    display: block !important;
  }

  button.btn.btn-primary.btnreset {
    margin-top: 15px;
  }


  // dashboard

  h2.mb-1.mainHeading_cls {
    font-size: 20px;
    font-weight: 600;
  }

  p.grayCls.mb-0 {
    font-size: 12px;
    font-weight: 200;
    color: #898989;
    width: auto !important;
  }

  a.btn.barlink.text-primary {
    // left: 8px;
    // width: 35px;
    // height: 35px;
    // padding: 7px 9px;
    left: 19px;
    width: 35px;
    height: 35px;
    padding: 7px 9px;
    top: 17px;

  }

  form.headerSearch.ml-3 {
    width: 100% !important;
    margin-left: 9px !important;
  }



  // .ml-3 {
  //   width: 200px;
  // }
  img.listIc {
    height: 40px;
    width: 40px;
  }

  .borderBox.d-flex.align-items-center.justify-content-between {
    padding: 0px 15px;

  }

  .timeBoxs p {
    font-size: 14px;
    color: #484848;
    line-height: 17px;
  }

  .headerSearch .Searchbar {
    width: 100%;
    max-width: 130px;
  }

  // form.headerSearch.ml-3 {
  //   width: 130px;
  // }

  // dashboard responsive
  .graph_range {
    display: block;
  }

  p.view_graph {
    margin-top: 18px;
  }


  // admin user




  span.side_head {
    font-size: 14px;
  }



  .table_section {
    margin-top: 10px !important;
  }

  .mr-2.phBottomSpace {
    margin-bottom: 10px;
  }

  a.btn.btn-primary.profiles {
    width: fit-content;
  }

  button.btn.btn-primary.mr-2.export {
    margin-left: 15px;
  }

  .hedding {
    font-size: 21px;
  }
}

@media only screen and (min-width:320px) and (max-width:441px) {
  button.btn.btn-primary.mr-2.exports {
    margin-top: 15px;
  }

  h5.heading_holidays {
    margin-top: 30px !important;
  }
}

// profile changes
input.searchbar.mr-2:focus {
  box-shadow: 0 3px 10px 0 17%;
  border-color: #7561a2;
}

a.btn.btn-primary.profiles {
  background-color: #fff !important;
  border: 1px solid #c2c2c2 !important;
  height: auto;
  color: #2020209c !important;
  border-radius: 12px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-between;

}

i.material-icons.prob {
  font-size: 27px;
  margin: 0px 7px;
  color: #0000004a;
}

label.btn.btn-primary.delete.ml-3 {
  background-color: #fff !important;
  border-radius: 10px;
  color: #7561a2 !important;
  font-size: 14px !important;
  font-weight: 500;
}

label.btn.btn-primary.edit.ml-3 {
  border-radius: 10px;
  font-weight: 600;
  font-size: 14px;
}

.btn.btn-secondary.discard {
  background-color: #fff !important;
  border: 1px solid #DCDCDC !important;
  color: #202020 !important;
  font-size: 14px !important;
  height: 43px;
  border-radius: 10px;
  font-weight: 500;

}

a.discard {
  text-decoration: none;
  color: #202020 !important;
  font-size: 14px;
  font-weight: 500;

  & :hover {
    color: #202020 !important;
    font-size: 14px;
    font-weight: 500;
  }
}

.btn.btn-primary.reset {
  background-color: #fff !important;
  border: 1px solid #DCDCDC !important;
  color: #202020 !important;
  font-size: 14px !important;
  height: 43px;
  border-radius: 10px;
}

button.btn.btn-primary.edit.ml-3 {
  border-radius: 10px;
  font-weight: 600;
  font-size: 14px;

}

.table-striped>tbody>tr:nth-of-type(odd)>* {
  --bs-table-color-type: #202020 !important;
  --bs-table-bg-type: rgb(249 249 249 / 100%) !important;
}

a.btn.barlink.text-primary.active {
  outline: none;
  border: none;
}

a.btn.barlink.text-primary:active {
  border: none;
}

p.profile_data {
  margin-bottom: 0px !important;
  font-size: 14px;
  font-weight: 400;
  color: #5A5A5A;
}

.col-md-12.inputFlex {
  line-height: 30px;
}

.highlightOption {
  background: #7561a2;
  color: #fff;
}

.multiSelectContainer li:hover {

  background: #7561a2 !important;
  color: #fff;
}


@media screen and (min-width:320px) and (max-width:767px) {
  .min-sidebar~div>.main-sidebar a.col_side:hover {
    justify-content: center;
    margin: 8px 14px !important;
  }

  .min-sidebar~div>.main-sidebar {
    display: block !important;
    max-width: 250px;
    min-width: 250px;
    width: 100%;
    min-height: 100vh;
    // margin-top: -72px;
    border-right: 1px solid #E8E8E8;
    top: 0px;
  }

.active-sidebar img.hide-logo {
   padding-left: 0px !important; 
}

  .sidees {
    display: flex;
  }

  .min-sidebar~div>.main-sidebar a.nav-link.hoverclass {
    padding: 9px 16px;
    border-radius: 12px !important;
    margin-right: 10px !important;
    display: flex !important;
    justify-content: left !important;
    width: auto !important;
  }

  .min-sidebar~div>.main-sidebar a.col_side {
    padding: 9px 16px;
    border-radius: 12px !important;
    margin-right: 10px !important;
    display: flex !important;
    justify-content: space-between !important;
    width: auto !important;
  }

  .min-sidebar~div>.main-sidebar .nav-link span {
    display: block;
  }

  .min-sidebar~div>.main-sidebar a.col_side span {
    display: flex;
    font-size: 15px;
    font-weight: 300;
    color: #202020;
  }

  .min-sidebar~div>.main-sidebar i {
    margin-right: 8px !important;
  }

 

  .main-sidebar .nav .nav-item .nav-link i.sidenv {
    display: none !important;
  }

  .card.card-body.sides li a {
    border-radius: 4px !important;
    padding-left: 42px !important;
  }
}

@media(min-width:768px) {
  .main-navbar.min-sidebar~div>.main-sidebar {
    display: block !important;
  }
}


// new css by deepak tailwind css


button[type='submit'] {
  -webkit-appearance: button;
  background-color: #1E5DBC;
  background-image: none;
}

body,
html {
  background-color: #f8f9fc;
  scroll-behavior: smooth;
}

.shadow-box {
  --tw-shadow: 0px 0px 0px 1px rgba(218, 186, 48, 0.08), 0px 1px 2px 0px rgba(164, 172, 185, 0.24) !important;
  --tw-shadow-colored: 0px 0px 0px 1px var(--tw-shadow-color), 0px 1px 2px 0px var(--tw-shadow-color) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}


input:focus-visible {
  outline: -webkit-focus-ring-color auto 0px;
}

.react-tel-input .form-control {
  width: 100%;
  border-radius: 8px;
}

.react-tel-input .flag-dropdown {
  border-radius: 8px 0 0 8px !important;
}


.navbar-tailwind {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
  // padding: .5rem 1rem;
}

.ancortag {
  color: #141414 !important;
}

.ancortag:hover {
  color: #1E5DBC !important;
  text-decoration: underline !important;
}


.active-sidebar img.hide-logo {
  display: block !important;
  width: 30px;
}

.active-sidebar img.show-logo {
  display: none !important;
}


img.hide-logo {
  display: none;
}

.active-sidebar .main-sidebar {
  padding: 8px;
  text-align: center;
}

.main-wrapper main.main {
  width: calc(100% - 280px);
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;
  margin-left: auto;
}

.min-sidebar~div>main.main {
  width: calc(100% - 65px);
}

.active-sidebar .space-y-2 li a {
  width: -moz-fit-content;
  width: fit-content;
  flex-wrap: wrap;
  gap: 0px;
  justify-content: center;
  flex-direction: column;
}

.active-sidebar .space-y-2 li button span.crm {
  flex-direction: column;
  width: 100%;
}

.active-sidebar .space-y-2 li span {
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  font-size: 9px;
  margin: 0 auto;
  
}
.active-sidebar h6.py-\[12px\].text-center.text-xs.font-medium.text-\[\#7E8B99\].mt-\[12px\] span{
  display: block;
}
.active-sidebar .pl-\[30px\] {
  padding-left: 0px;
}

.active-sidebar .px-\[8px\] {
  padding: 0px;
}

.active-sidebar .sidebar_text {
  font-size: 7px;
  display: flex;
  line-height: 9px;
  flex-wrap: wrap;
  justify-content: center;
}


.active-sidebar span.text-sm {
  flex-wrap: wrap;
  line-height: 10px;
  gap: 0px;
}


.active-sidebar .sidebar_sm_sidebar__KuhlO ul li a {
  width: -moz-fit-content;
  width: fit-content;
  margin: 0;
  width: 55px;
}

.active-sidebar .sidebar_sm_sidebar__KuhlO ul li button {
  padding-right: 0;
  padding-left: 0;
  text-align: center;
  width: 55px;

  margin: 0;
  gap: 0px !important;
}


.active-sidebar .space-y-2 li button svg.h-5.w-5.text-purple-500 {
  display: none;
}

.active-sidebar .pl-\[30px\] {
  padding-left: 0px;
}

.active-sidebar h6.py-\[12px\].text-center.text-xs.font-medium.text-\[\#7E8B99\].mt-\[12px\] {
  width: 60px;
}


.dropdown-item.active,
.dropdown-item:active {
  border-radius: 10px;
}


// react pagination style css start


.react-pagination-js-default ul>li:first-child {
  margin-left: auto !important;
  border-radius: 8px;
  background: white;
  border: 1px solid #d0d5dd !important;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05) !important;
  padding: 10px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(52, 64, 84, 0.48) !important;
  font-size: 14px !important;
  font-weight: 500 !important;
}

.react-pagination-js-default li.page {
  border-color: #0000 !important;
  background-color: transparent !important;
  box-shadow: none !important;
  font-weight: 400 !important;
}

li.page.disabled {
  cursor: default;
  pointer-events: none;
  color: #999999;
}

.react-pagination-js-default .page {
  display: inline-block;
  padding: 0px 9px;
  margin-right: 4px;
  border-radius: 3px;
  border: solid 1px #c0c0c0;
  background: #e9e9e9;
  box-shadow: inset 0px 1px 0px rgba(255, 255, 255, .8), 0px 1px 3px rgba(0, 0, 0, .1);
  font-weight: bold;
  text-decoration: none;
  color: #717171;
  text-shadow: 0px 1px 0px rgba(255, 255, 255, 1);
  cursor: pointer;
}

.react-pagination-js-default ul>li {
  margin: 0 !important;
  font-size: 13px !important;
  padding: 7px 13px !important;
}


.react-pagination-js-default ul>li:last-child:not(.disabled) {
  color: #1E5DBC !important;
  font-size: 14px !important;
  font-weight: 500 !important;
}

.react-pagination-js-default ul>li:last-child:not(.disabled) {
  color: #1E5DBC !important;
}

.react-pagination-js-default ul>li:last-child {
 
  border-radius: 8px;
  background: white;
  border: 1px solid #d0d5dd !important;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05) !important;
  padding: 10px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(52, 64, 84, 0.48) !important;
  font-size: 14px !important;
  font-weight: 500 !important;
}


.react-pagination-js-default {
  width: 100%;
}


// .main-sidebar i.material-icons.mr-2.text-\[\#00b884\].shrink-0.text-lg {
//   color: #5e63d8;
// }

.active-sidebar .space-y-2 li button svg.h-4.w-4.transition-all.duration-500.text-\[\#7E8B99\] {
  display: none;
}



.custom_dropdown .removeBg {
  width: 100% !important;
  position: relative;
  height: 2.5rem !important;
  align-items: center;
  border: none;
  display: flex;
  border: 1px solid #fff !important;
  border-radius: 0.5rem !important;
  --tw-bg-opacity: 1 !important;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity)) !important;
  --tw-shadow: 0px 0px 0px 1px rgba(18, 55, 105, 0.08), 0px 1px 2px 0px rgba(164, 172, 185, 0.24) !important;
  --tw-shadow-colored: 0px 0px 0px 1px var(--tw-shadow-color), 0px 1px 2px 0px var(--tw-shadow-color) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.list_box_active_state button[data-headlessui-state="open"] {
  outline-color: #1E5DBC;
  outline-width: 2px;
  outline-style: solid !important;
}


.active-sidebar ul.space-y-2.px-2 {
  padding: 0px 0px !important;
  margin: 0 0px;
}


.pprofile1 {

  width: 100%;
  margin-right: auto;
  margin-left: auto;
  background-color: #fff;
  border-radius: 5px;
  --tw-shadow: 0px 0px 0px 1px rgba(18, 55, 105, 0.08), 0px 1px 2px 0px rgba(164, 172, 185, 0.24) !important;
  --tw-shadow-colored: 0px 0px 0px 1px var(--tw-shadow-color), 0px 1px 2px 0px var(--tw-shadow-color) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.rounded-lg {
  border-radius: .5rem !important;
}

.list_box_active_state a:hover {
  background-color: #1E5DBC;
  color: #fff;
}

.options_classs .css-1nmdiq5-menu {
  border: 2px solid;
  border-color: #1E5DBC;
  width: 100% !important;
}


td.\!text-typo.\!border-l-0.\!px-3\.5.text-sm.font-normal.\!py-4.\!border.text-center.border-\[\#EAECF0\] {
  text-transform: capitalize;
}


.css-tr4s17-option {
 
  background-color: #1E5DBC !important;
 
}

.css-t3ipsp-control:hover {
  border-color: #1E5DBC !important;
}

.css-t3ipsp-control {

  box-shadow: 0 0 0 1px #1E5DBC !important;
  border-radius: 0.5rem !important;
}

.css-13cymwt-control{

  width: 100%;

  position: relative;
  overflow: hidden;
  height: auto !important;
  align-items: center;
  display: flex;
  border: 0px !important;
  --tw-bg-opacity: 1;
  background-color: rgba(255,255,255,var(--tw-bg-opacity));
  border-radius: 0.5rem !important;
  --tw-shadow: 0px 0px 0px 1px rgba(218, 186, 48, 0.08), 0px 1px 2px 0px rgba(164, 172, 185, 0.24) !important;
  --tw-shadow-colored: 0px 0px 0px 1px var(--tw-shadow-color), 0px 1px 2px 0px var(--tw-shadow-color) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow) !important;
}

.mobile_number .form-control {
  width: 100% !important;
  position: relative;
  height: 2.5rem !important;
  align-items: center;
  border: none;
  display: flex;
  border-radius: 0.5rem !important;
  --tw-bg-opacity: 1 !important;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity)) !important;
  --tw-shadow: 0px 0px 0px 1px rgba(18, 55, 105, 0.08), 0px 1px 2px 0px rgba(164, 172, 185, 0.24) !important;
  --tw-shadow-colored: 0px 0px 0px 1px var(--tw-shadow-color), 0px 1px 2px 0px var(--tw-shadow-color) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}


.mobile_number .react-tel-input .flag-dropdown {
  position: absolute;
  top: 0;
  bottom: 0;
  padding: 0;
  background-color: #f5f5f5;
  border: 1px solid #fff;
  border-radius: 0.5rem 0 0 0.5rem !important;
}

.borders_data{
  border-radius: 30px;
border: 0.3px solid rgba(0, 0, 0, 0.63);
background: #FFF;
box-shadow: -6px 4px 13.2px 0px rgba(0, 0, 0, 0.11);
}

.options_classs {
  position: relative;
}

.imagesRow {
  margin-top: 15px;
}
.sidebar_sm_sidebar__KuhlO {
  width: 60px !important;
  min-width: 60px !important;
}
@keyframes slowShake {
  0%, 100% { transform: translateX(0); }
  20% { transform: translateX(-5px); }
   100% { transform: translateX(5px); }
}
.slow-shake {
  animation: slowShake 2s infinite;
}

.desc-text br {
  display: none;
}
.ql-toolbar.ql-snow {
  border: 1px solid #ccc;
  box-sizing: border-box;
  font-family: 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
  padding: 8px;
  border-top-left-radius: 6px !important;
  border-top-right-radius: 6px !important;
}

.rejected {
  background: rgb(255 0 0 / 58%);
  padding: 4px 6px;
  border-radius: 4px;
  color: rgb(255 255 255) !important;
  width: 167px;
  display: block;
  text-align: center;
}

.pending {
  background: rgba(207, 176, 126, 0.3215686275);
  padding: 4px 6px;
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.85);
  width: 90px;
  display: block;
  text-align: center;
  text-transform: capitalize;
}

.customstatus {
  background: rgba(245, 116, 127, 0.322);
  padding: 4px 6px;
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.85);
  width: 90px;
  display: block;
  text-align: center;
  text-transform: capitalize;
}

.in-progress {
  background: rgb(237 236 176 / 70%);
  padding: 4px 6px;
  border-radius: 4px;
  color: #525252 !important;
  width: 82px;
  display: block;
  text-align: center;
  text-transform: capitalize;
  font-size: 13px;
}
.completed {
  background: #76b3f7;
  padding: 4px 6px;
  border-radius: 4px;
  color: #ffffff !important;
  width: 82px;
  display: block;
  font-size: 13px;
  text-align: center;
  text-transform: capitalize;
}
.upload-big label {
  padding: 25px 0 !important;
}


.custom-shadow{
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.inner-shadow{
  
    box-shadow: rgb(204, 219, 232) 3px 1px 11px -3px inset, rgba(255, 255, 255, 0.5) 2px -3px 8px 1px inset;

}
.c-dropdown button {
  display: flex;
  justify-content: space-between;
}


.bg-img {
  background-image: url("../../public/assets/img/signin.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
}
.shadow-c{
    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;

}
.ellipses{
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  max-width: 185px;
}
.ellipses-two {
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
}
.width-fit {
  width: fit-content;
}
.min-sidebar ~ div > main.main {
  width: calc(100% - 66px);
}
td span, th span{
  white-space: nowrap;
}
.css-13cymwt-control {
  border: 1px solid #00000036 !important;
  box-shadow: none !important;
  min-height: 44px !important;
  overflow-y: auto;
}
.css-1dyz3mf {
  overflow-y: auto !important;
    height: auto !important;
  // height: 40px !important;
}
.css-t3ipsp-control {
  min-height: 44px !important;
  height: auto !important;
}
.rdrSelected, .rdrInRange, .rdrStartEdge, .rdrEndEdge {
  background: #0b5cb8 !important;
  position: absolute;
  top: 5px;
  left: 0;
  right: 0;
  bottom: 5px;
}
.active-sidebar .profile-hidden b, .active-sidebar .profile-hidden p {
  display: none;
}
.active-sidebar .delete-drop {
  display: none;
}
.active-sidebar .profile-hidden {
  padding: 4px !important;
}
.active-sidebar .sidebar-padding {
  padding: 0px !important;
}
.active-sidebar .space-y-2 li span:first-child {
  display: block;
}
/* width */
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #cad8e9; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #cad8e9; 
}
.material-category .css-13cymwt-control {
  border: 1px solid #00000036 !important;
  box-shadow: none !important;
  height: unset !important;

}
.material-category  .css-1dyz3mf {
  height: unset !important;
}
.material-category  .css-t3ipsp-control {
 
  height: unset !important;
}
textarea.relative.border.border-\[\#00000036\].bg-white.w-full.rounded-lg.h-\[45px\].flex.items-center.gap-2.overflow-hidden.px-3.pt-2.abc {
  height: 150px;
}
// Home page
.banner-img {
  background-image: url("../../public/assets/img/banner.jpeg");
  background-repeat: no-repeat;
  background-size: cover;
}
.about-bg {
  background-image: url("../../public/assets/img/about.jpg");
  height: 400px;
  display: block;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
}
.css-13cymwt-control {
  min-height: 44px;
}
.full_width .react-datepicker-wrapper {
  width: 100%;
}
.client_name .capitalize .text-gray-700.block.text-sm.options_classs.css-3iigni-container {
  height: 44px;
}
.client_name .capitalize .text-gray-700.block.text-sm.options_classs.css-3iigni-container .css-16xfy0z-control{
  height: 44px;
}

.active-sidebar .sidebar_sm_sidebar__KuhlO ul li a {

  width: 100%;
  padding: 10px 3px;
}
.active-sidebar .sidebar-center img{
  margin: 0 auto;
}
.active-sidebar .space-y-2 li span {
  margin-top: 4px;
  font-size: 9px;

}
.active-sidebar .sidebar_sm_sidebar__KuhlO ul li a img{
  margin: 0 auto;
}
.active-sidebar .center-set-inner{
  display: flex;
  flex-direction: column !important;
  justify-content: center;
}
.active-sidebar .center-set span{
  display: block !important;

}
.active-sidebar .position-set {
  position: unset !important;
}
.active-sidebar  .position-text{
  margin-left: 0;
  padding-left: 0;
}
.active-sidebar .center-set {
  padding: 0 !important;
}
.min-sidebar ~ div > .main-sidebar {
  width: 78px;
  min-width: 68px;
}
@media (max-width: 1199px) {
  .banner-img {
    background-image: url("../../public/assets/img/banner1.jpeg");

  }
 
}
@media (max-width: 991px) {
  .flex-col-reverse-custom{
    display: flex !important;
    flex-direction: column-reverse !important;
    align-items: flex-start !important;
  }
  .active-sidebar main.main {
    width: calc(100% - 0px) !important;
}
.min-sidebar ~ div > .main-sidebar{
  display: none;
}
}


  @media only screen and (max-width: 767px){
    .w-\[calc\(100\%-280px\)\] {
      width: 100% !important;
    }
    .ant-picker-panels {
      flex-direction: column !important;
    }
    .rdrMonthAndYearPickers select {
      padding: 10px 16px 10px 1px !important;
  }

  .main-wrapper main.main {
    width: calc(100% - 0px);
  
  }
  .main-sidebar {
    display: none;
}
  .min-sidebar ~ div > .main-sidebar {
    display: block !important;
    max-width: 250px;
    min-width: 250px;
    width: 100%;
    min-height: 100vh;
    border-right: 1px solid #e8e8e8;
    top: 0px;
}
  .min-sidebar ~ div > .main-sidebar {
    max-width: 78px !important;
    min-width: 68px !important;
}
   
    .min-sidebar ~ div > main.main {
      width: calc(100% - 66px) !important;
  }
  }
  